import {useState, useContext, useEffect, useRef} from 'react'

import {redeem} from "../../utils/interactions"
import DappContext from '../../context';

import { ProgressSpinner } from 'primereact/progressspinner';    
import { Toast } from 'primereact/toast';

import {db} from "../../utils/firebase";
import { collection, setDoc, getDocs, doc, updateDoc} from "firebase/firestore";


const TxHandler = ({merch, amount, close, showToast, idx}) => {
    const {account} = useContext(DappContext);

    const toast = useRef();

    const [txHash, setTxHash] = useState("")
    const [processing, setProcessing] = useState(false)
    const [successfullyRedeemed, setsuccessfullyRedeemed] = useState(false)

    

    useEffect(() => {
        if(txHash !== ""){
            setProcessing(false)
        }
    }, [txHash])

    useEffect(() => {
        redeemx()
        
    }, [])
    
    const redeemx = async() =>{

        setProcessing(true);
        
        redeem(amount).then(async (tx)=>{
            setTxHash(tx.hash);
            const receipt = await tx.wait();
            
            setProcessing(false)
            setsuccessfullyRedeemed(true);
            if(!merch){
                await checkIfinDb()
            }
        }).catch((e)=>{
            setProcessing(false)
            
            if(e.error){
                merch
                    ?showToast([{severity: 'warn', summary: "Error", detail: e.error.message, life: 5000}, merch])
                    :showToast([{severity: 'warn', summary: "Error", detail: e.error.message, life: 5000}, merch])
                // toast.current.show({severity: 'warn', summary: "Error", detail: e.error.message, life: 5000});
            }else{
                merch
                    ?showToast([{severity: 'warn', summary: "Error", detail: e.message, life: 5000}, merch])
                    :showToast([{severity: 'warn', summary: "Error", detail: e.message, life: 5000}, merch])
                // toast.current.show({severity: 'warn', summary: "Error", detail: e.message, life: 5000});
            }
            
            close()
            setsuccessfullyRedeemed(false);
            
        })
    }
    const getIds = async() =>{
        const querySnapshot = await getDocs(collection(db, "merch_nfts"))
        var id = 1;
        querySnapshot.forEach((doc) => {
            id++;
        });
        
        return id.toString();
    }
    const addRedeem = async() => {
            const idx = await getIds();
            await setDoc(doc(db, "merch_nfts", idx), {
                wallet: account, 
                amount: amount
            });
    }
    const updateRedeem = async(entry) => {
        const oldAmount = entry[0].amount;
        console.log("index",typeof entry[1])
        await updateDoc(doc(db, "merch_nfts",entry[1].toString()), {
            amount: parseInt(oldAmount) + parseInt(amount)
        });
    }

    const checkIfinDb = async() =>{
        const querySnapshot = await getDocs(collection(db, "merch_nfts"))

        
        var x = 1
        var list = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            var entry = doc.data()
            if(entry.wallet.toUpperCase() === account.toUpperCase()){
                list.push(entry)
                list.push(x)
            }
            x++;
        });

        if(list.length===0){
            await addRedeem();
        }else{
            await updateRedeem(list)
        }
    }

    return (
        <>
            <Toast ref={toast} position="top-center" />
            <div>
                {processing
                    ?<div className='load'>
                        <br/>
                        <ProgressSpinner />
                        <br/>
                        <br/>
                        <div className='message'>Confirm transaction in your wallet...</div>
                    </div>
                    :txHash !== "" 
                        ? !successfullyRedeemed 
                            ?<div className='message'>
                                Your transaction has been submitted and is awaiting confirmation. You can view your transaction <a href={"https://etherscan.io/tx/" + txHash} target="_blank" rel="noopener noreferrer">here</a>
                                <br/>
                                <br/>
                                <ProgressSpinner />
                                <br/>
                            </div> 
                            : !merch
                                ?<div className='message'>
                                    <div className='success'>Congrats! You've successfully redeemed your NFT</div>
                                    <br/>
                                </div>
                                :<div className='message'>
                                    <div className='success'>Congrats! You've successfully redeemed your merch</div>
                                    <br/>
                                </div>
                        : <div className='message'></div>
                }
            </div>
        </>
       
    )
}

export default TxHandler