import {useState, useContext, useEffect, useRef} from 'react'
import { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { Environment, OrbitControls } from "@react-three/drei";
import { motion } from "framer-motion"


import {db} from "../../utils/firebase";
import { collection, getDocs, doc, getDoc} from "firebase/firestore";


import DappContext from "../../context";
import {currentState, approve, balance, isFreeMint, checkState, getPrice} from "../../utils/interactions"

import './Mint.css';
import { Button } from 'primereact/button'; 
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';


import Redeem from "../Redeem/Redeem";
import Hoodie from "../Hoodie/Hoodie";
import Popup from '../Popup/Popup';
import Admin from "../Admin/Admin";
import Info from '../Info/Info';
import Orders from '../Orders/Orders';
import Items from '../Redeem/Items';



const Mint = () => {

    const {account, isLoading, isAuthenticated, authenticate,  approved, onList, minted, cs, isAdmin} = useContext(DappContext);

    const toast = useRef();
    const [displayBasic, setDisplayBasic] = useState(false);
    
    const [alreadyMinted, setAlreadyMinted] = useState(false)

    const [redeem_amount, setRedeemAmount] = useState(1)
    const [maxRedeem, setMaxRedeem] = useState(0)
    const [redeemAmounts, setredeemAmounts] = useState([])
    const [balance1, setbalance1] = useState(0)
    const [balance2, setbalance2] = useState(0)

    const [adm, setadm] = useState(false)

    const [startDate, setStartDate] = useState("")
    const [physicalRedeemed, setphysicalRedeemed] = useState()

    const [showOrdersDialog, setshowOrdersDialog] = useState(false)
    const [countdownLabel, setCountdownLabel] = useState()
    const [currentCountdown, setCurrentCountdown] = useState(1)
    const [countdownBlocking, setCountdownBlocking] = useState(false)

    const [totalMinted, setTotalMinted] = useState()
    

    useEffect(() => {
        setAlreadyMinted(minted);
        isAdmin && setadm(true);
        checkBalance();
        redeemAmountList();
        

    }, [isAuthenticated])

    useEffect(() => {
        checkRedeems();
    }, [balance2])

    useEffect(() => {
        async function check(){
            const totalMinted = await checkState();
            setTotalMinted(totalMinted)
        }
        const stateChecker = setInterval(() => {
            check()
        }, 2000);
    
      return () => clearInterval(stateChecker)
    },[])
    
    
    
    const checkRedeems = async() =>{
        const count = parseInt(await getNfts());
        const orders = parseInt(await getOrders());
        if(orders + count < balance2){
            setphysicalRedeemed(balance2 - (count + orders));
            setDisplayBasic(true);
            // alert(`You have redeemed ${balance2 - count} physical merch pieces and you didn't provide a shipping informations...` )
        }
    }

    const getNfts = async () =>{
        const querySnapshot = await getDocs(collection(db, "merch_nfts"))
        const items = {}
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            items[doc.id] =  doc.data()
        });
        var x = 0

        Object.keys(items).forEach((order) =>{
            let wallet = items[order].wallet;
            if(wallet.toUpperCase() === account.toUpperCase()){
                x = items[order].amount
            }
        })
        return x;
    }
    const getOrders = async () =>{
        const querySnapshot = await getDocs(collection(db, "merch_orders"))
        const items = {}
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            items[doc.id] =  doc.data()
        });
        var x = 0;
        Object.keys(items).forEach((order) =>{
            let wallet = items[order].wallet;
            if(wallet.toUpperCase() === account.toUpperCase()){
                var amount = parseInt(items[order].quantity)
                x+= amount;
            }
        })
        return x
    }
    useEffect(() => {
        checkBalance();
    }, [alreadyMinted])
    
    const checkBalance = async() =>{
        const bal1 = await balance(account, 1);
        const bal2 = await balance(account, 2);
        setbalance1(bal1)
        // setbalance1(10)
        setbalance2(bal2)  
    }

    const redeemAmountList = async() =>{
        const list = []
        for(let x =1; x<=balance1; x++){
            list.push({name:x})
        }
        setredeemAmounts(list)

    }
    
    async function getCountdown(){
        const docRef = doc(db, "merch", `countdown${currentCountdown}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let start = `${docSnap.data().date} ${docSnap.data().time}`;
            setStartDate(start);
            let label = `${docSnap.data().label}`;
            setCountdownLabel(label);
            let block = docSnap.data().block === "true" ? true : false;
            setCountdownBlocking(block)
            setCurrentCountdown(parseInt(currentCountdown)+1)
        } else {
            console.log("No such document! 2", currentCountdown);
        }
    } 

    

    async function getCurrentCountdown(){
        const docRef = doc(db, "merch", "currentCountdown");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let cc = `${docSnap.data().text}`;
            setCurrentCountdown(cc);
        } else {
            console.log("No such document! 1");
        }
    }     

    // useEffect(() => {
    //     getCountdown()
    // }, [])
    
    const calculateTimeLeft = () => {
        let difference = +new Date(`${startDate} UTC`) - +new Date();
        // let difference = +new Date(`04/12/2022 21:00:00`) - +new Date();
        let timeLeft = {};
      
        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          };

        }else{
            
            getCountdown()
            
        }
        return timeLeft;
    }
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        const timer = setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
      
        return () => clearTimeout(timer);
    });
    const timerComponents = [];
    let c=0;
    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
          return;
        }
      
        timerComponents.push(
          <span style={{color:"#3333ff"}} key={c}>
            {timeLeft[interval]} {interval}{" "}
          </span>
        );
        c+=1;
    });
    
    const onHide = (showOrders) => {
        if(showOrders){
            setshowOrdersDialog(true)
        }else{
            setDisplayBasic(false)
        }
    }

    const showOrders = () =>{
        setDisplayBasic(true)
        setshowOrdersDialog(true)
    }
    const renderFooter = () => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => onHide(false)} className="p-button-text" style ={{color:'#070707', background: "#3333ff00", border:"1px solid #808080", width: "80px"}}  />
                <Button label="Yes" icon="pi pi-check" onClick={() => onHide(true)} autoFocus style ={{color:'#070707', background: "#3333ff00", border:"1px solid #808080", width: "80px"}} />
            </div>
        );
    }

    return (
        <>
        <Dialog  visible={displayBasic} style={{ width: '100vw', color:"#000", minHeight: "200px" }} onHide={() => onHide(false)}>
            {showOrdersDialog
            ?<Items amount={physicalRedeemed} isMerch={true} makeTx={false}/>
            :<div className='orders-pop'>
                <div className='popup-msg'>You have {physicalRedeemed} unredeemd token{physicalRedeemed >1 ? "s" : ""} would you like to redeem {physicalRedeemed >1 ? "them" : "it"} for physical merch?</div>
                <div className="space"></div>
                <div className='orders-footer'>
                    <Button label="No" icon="pi pi-times" onClick={() => onHide(false)} className="p-button-text" style ={{color:'#070707', background: "#3333ff00", border:"1px solid #808080", width: "80px"}}  />
                    <Button label="Yes" icon="pi pi-check" onClick={() => onHide(true)} autoFocus style ={{color:'#070707', background: "#3333ff00", border:"1px solid #808080", width: "80px"}} />
                </div>
            </div>
            }
        </Dialog>
        {adm && <Admin />}
        {/* {isAuthenticated && 
        <div className={adm ? 'orders-btn':'admin-btn'}>
            <Button label={"your orders"} style ={{color:'#070707', background: "#3333ff00",  border:"1px solid #808080", width: "157px"}} className=" p-button-info p-button-text" onClick={() =>showOrders()}  />
        </div>} */}
        <div className='mint-container'>
            <div className='socials'>
                <a href="https://opensea.io/collection/siblingsanniversary" target="_blank" rel="noopener noreferrer"><img className='ico' src="/OS.png" alt="" /></a>
                <a href="https://looksrare.org/collections/0xc118d78a29B37ab395Cdc54ec8B3D36D80604A86?queryID=8960a3a5f28b21fd804677931fb7db02&queryIndex=prod_tokens" target="_blank" rel="noopener noreferrer"><img className='ico' src="/LR.png" alt="" /></a>
                <a href="https://nft.coinbase.com/collection/ethereum/0xc118d78a29b37ab395cdc54ec8b3d36d80604a86" target="_blank" rel="noopener noreferrer"><img className='ico' src="/Coinbase.png" alt="" /></a>
                <a href="https://etherscan.io/address/0xc118d78a29b37ab395cdc54ec8b3d36d80604a86" target="_blank" rel="noopener noreferrer"><img className='ico' src="/etherscan.png" alt="" /></a> 
                <a href="https://twitter.com/NFTSiblings" target="_blank" rel="noopener noreferrer"><img className='ico' src="/twitter.png" alt="" /></a> 
                <a href="https://discord.gg/Siblings" target="_blank" rel="noopener noreferrer"><img className='ico' src="/discord.png" alt="" /></a> 
                
            </div>
            <Toast ref={toast} position="top-center" />
            <div className='hoodie'>
                <Canvas >
                <Suspense fallback={null}>
                    <Hoodie/>
                    <OrbitControls enableZoom={false} enablePan={false}/>
                    <Environment preset="sunset"  />
                </Suspense>
                </Canvas>
            </div>
            {/* <div className='line '></div> */}
            <div className='light cntr'></div>
            <div className='light left'></div>
            <div className='light right'></div>
            <motion.div 
                animate={{ scale: 1.1 }} 
                className="title"
            >
            <span aria-hidden="true">MERCH</span>
            <span aria-hidden="true">MERCH</span>
            <span aria-hidden="true">MERCH</span>
            MERCH
            <span aria-hidden="true">MERCH</span>
            </motion.div>
            <motion.div 
                initial={{scale:0}}
                animate={{ scale: 1 }}
                transition={{ delay: 0.3, type: "spring", stiffness: 100 }}
                className="sub-title"
            >BY NFT SIBLINGS</motion.div>
            {isAuthenticated &&<div>PHASE {cs.phase}</div>}
            <br/>
            
            {timerComponents.length ? <div className='timer'>Only { timerComponents } until {countdownLabel}</div>:<div></div>}
            <br/>
            {isAuthenticated?(
                // AUTHENTICATED     -----  !cs.active &&
                <>
                
                
                <div className="account">
                    
                    <div className="connect-button">
                        <motion.div 
                            initial={{opacity:0, y:-100}}
                            animate={{ opacity: 1, y:0 }}
                            transition={{ delay: 0.0, type: "spring", stiffness: 200 }}
                            className="address"
                        >Connected to: {account.substring(0, 5) + "...." + account.substring(account.length - 4)}</motion.div>
                        {!countdownBlocking
                        ?<><div className="balance">
                            <div className="token">
                                Crates: {balance1}
                            </div>
                            <div className="token">
                                Hoodies: {balance2}
                            </div>
                        </div>
                        {/* <div className='supply'>Minted {cs.totalMinted.toNumber()}/{cs.totalSupply}</div> */}
                        </>
                        :<div></div>}
                        <br/>
                        <>{!countdownBlocking
                            ?cs.active && cs.phase === 1 
                                ?cs.totalMinted.toNumber() < cs.totalSupply
                                    ? alreadyMinted
                                        ? balance1 !== 0 
                                            ?  <>
                                                    <div className='info-msg'>Congrats! Now that you have the Sibilngs 1 Year Hoodie Crate lets Celebrate! Redeem your Crate for a physical + virtual hoodie or if you don't want the physical hoodie redeem for just the virtual hoodie. SIBLINGS SIBLINGS SIBLINGS!</div>
                                                    <div  className='redeem'>
                                                        {/* <Dropdown value={redeem_amount} options={redeemAmounts} onChange={(e) => {setRedeemAmount(e.value)}} optionLabel="name" placeholder="1" /> */}
                                                        <Redeem balance={balance1} checkBalance={checkBalance}/>
                                                    </div>
                                                </>
                                            :  <div className='info-msg'>Congrats! You have successfully redeemed your token.</div>

                                        : balance1 === 0 
                                            ?cs.allowList
                                                ? onList 
                                                    ?<>
                                                        <Popup setMinted={setAlreadyMinted} isAuthenticated={isAuthenticated} isApproved={approved} />
                                                        {/* <div className='supply'>Minted {cs.totalMinted.toNumber()}/{cs.totalSupply}</div> */}
                                                    </>
                                                    :<div className='info-msg'>You are not on the AllowList.</div>
                                                : <>
                                                    <Popup setMinted={setAlreadyMinted} isAuthenticated={isAuthenticated} isApproved={approved} />
                                                    
                                                </>
                                            : <>
                                                <div className='info-msg'>Congrats! Now that you have the Sibilngs 1 Year Hoodie Crate lets Celebrate! Redeem your Crate for a physical + virtual hoodie or if you don't want the physical hoodie redeem for just the virtual hoodie. SIBLINGS SIBLINGS SIBLINGS!</div>
                                                <div  className='redeem'>
                                                    {/* <Dropdown value={redeem_amount} options={redeemAmounts} onChange={(e) => {setRedeemAmount(e.value)}} optionLabel="name" placeholder="1" /> */}
                                                    <Redeem  balance={balance1} checkBalance={checkBalance}/>
                                                </div>
                                            </>
                                    : balance1 === 0 
                                    ? <div className='info-msg'>The sale has sold out, grab one on secondary!</div>
                                    :<>
                                        <div className='info-msg'>Congrats! Now that you have the Sibilngs 1 Year Hoodie Crate lets Celebrate! Redeem your Crate for a physical + virtual hoodie or if you don't want the physical hoodie redeem for just the virtual hoodie. SIBLINGS SIBLINGS SIBLINGS!</div>
                                        <Redeem  balance={balance1} checkBalance={checkBalance}/>
                                    </>
                                : cs.phase === 2 
                                    ?balance1 !== 0 
                                        ?  <>
                                            <div className='info-msg'>Congrats! Now that you have the Sibilngs 1 Year Hoodie Crate lets Celebrate! Redeem your Crate for a physical + virtual hoodie or if you don't want the physical hoodie redeem for just the virtual hoodie. SIBLINGS SIBLINGS SIBLINGS!</div>
                                            <div  className='redeem'>
                                                {/* <Dropdown value={redeem_amount} options={redeemAmounts} onChange={(e) => {setRedeemAmount(e.value)}} optionLabel="name" placeholder="1" /> */}
                                                <Redeem balance={balance1} checkBalance={checkBalance}/>
                                            </div>
                                        </>
                                        : <div className='info-msg'></div>
                                    :<div className='info-msg'>SALE IS NOT ACTIVE</div>
                            :<div className='info-msg'></div>
                            }
                         </>
                       
                        
                    </div>
                
                </div>
                </>

                ):( 
                <>
                <div className="account">
                    
                    <motion.div 
                        initial={{scale:0}}
                        animate={{ scale: 1 }}
                        transition={{ delay: 0.4, type: "spring", stiffness: 100 }}
                        className="connect-button"
                    >
                        <Button label={isLoading? "loading": "Connect Wallet"} style ={{color:'#070707', background: "#3333ff00",  border:"1px solid #808080", width: "157px"}} className=" p-button-info p-button-text" onClick={() =>{authenticate()}} iconPos="right" loading={isLoading} />
                    </motion.div>
                </div>
                </>
                )
            }
            
            <>
               
                
            </>
        </div>
        </>
    )
   
}

export default Mint