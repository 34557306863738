import React, { useState, useEffect, useRef , useContext} from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { ContextMenu } from 'primereact/contextmenu';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';


import './Admin.css'

import {db} from "../../utils/firebase";
import { collection, getDocs, doc, setDoc, getDoc} from "firebase/firestore";

import {activateSale} from "../../utils/interactions"
import DappContext from "../../context";

const Admin = () => {
    const {cs} = useContext(DappContext);
    const [displayAdmin, setDisplayAdmin] = useState(false)
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    const [selectedOrder, setSelectedOrder] = useState(null);

    const [startDate, setDate] = useState("")
    const [countdownLabel, setCountdownLabel] = useState("")

    const toast = useRef()
    const dt = useRef(null)

    const statuses = [
        'sent', 'waiting', 
    ];
    const cm = useRef(null);
    const menuModel = [
        {label: 'Mark as sent', icon: 'pi pi-check-circle', command: () => changeOrderStatus(selectedOrder, "sent")},
        {label: 'Mark as waiting', icon: 'pi pi-clock', command: () => changeOrderStatus(selectedOrder, "waiting")},
    ];

    const changeOrderStatus = async(order, status) => {
        console.log("status changed", order)
        await setDoc(doc(db, "merch_orders", order.id), {
            ...order,
            status: status
        });
        await getOrders();
    }

    const setCountdown = async() => {

        if(startDate.split(" ").length > 1){
            await setDoc(doc(db, "merch", "countdown"), {
                date: startDate.split(" ")[0],
                time: startDate.split(" ")[1]
            });
        }else{
            toast.current.show({severity: 'warn', summary: "Unsupported date format", detail: "e.g. 12/30/2022 9:00:00", life: 300000});
        }
    }
    const setLabel = async() => {
            await setDoc(doc(db, "merch", "countdownLabel"), {
                text: countdownLabel
            });
    }


    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'country': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    
    const formatDate = (value) => {
        const d = new Date(value)
        return d.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }
    
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    }
    const renderHeader = () => {
        return (
            <>
            <h3 className="m-0">Orders</h3>
            <div className="search">
                <Button  icon="pi pi-refresh" onClick={() => getOrders()} style ={{color:'#070707', background: "#3333ff00",  border:"1px solid #808080", width: "50px", marginRight:"20px"}}/>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
            </>
        )
    }
    const header = renderHeader();

    // row templates
    const statusBodyTemplate = (rowData) => {
        return <span className={`customer-badge status-${rowData.status}`}>{rowData.status}</span>;
    }

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    }
    

    // generate orders list
    const getOrders = async () =>{
        const querySnapshot = await getDocs(collection(db, "merch_orders"))
        const items = {}
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            items[doc.id] =  doc.data()
        });
        const ordersList = []

        Object.keys(items).forEach((order) =>{
            ordersList.push(items[order])
        })
        setOrders(ordersList)
        setLoading(false)
    }
    
    useEffect(() => {
        getOrders();
        setSaleActive(cs.active)
        getDate()
    }, [])

    const [saleActive, setSaleActive] = useState()
    const sale = async(value) =>{
        await activateSale(value);
        setSaleActive(value)
    }

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    }

    
    async function getDate(){
        const docRef = doc(db, "merch", "countdown");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let start = `${docSnap.data().date} ${docSnap.data().time}`;
            setDate(start);
        } else {
            console.log("No such document!");
        }
    }   
        
    
    return (
        <>
        <Toast ref={toast} position="top-center" />
        <ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedOrder(null)}/>
        <Dialog header="" visible={displayAdmin} style={{ width: '100vw', height:"100vh", backgroundColor: "#F6F6F6"}}  onHide={() => setDisplayAdmin(false)} breakpoints={{'960px': '75vw', '640px': '100vw'}}>
            
            {/* countdown */}
            <div className='dialog-row'>
                <div className="dialog-row start">
                    <h3 className="m-0">sale active</h3>
                    <InputSwitch checked={saleActive} onChange={(e) => sale(e.value)} />
                    <div className='separator'></div>
                    <h3 className="m-0">set countdown (UTC Time)</h3>
                    <InputText value={startDate} onChange={e => setDate(e.target.value)} placeholder="12/30/2022 17:00:00" />
                    <Button  label='set' onClick={() => setCountdown()} style ={{color:'#070707', background: "#3333ff00",  border:"1px solid #808080", width: "50px", marginRight:"20px"}}/>

                    <InputText value={countdownLabel} onChange={e => setCountdownLabel(e.target.value)} placeholder="until..." />
                    <Button  label='set' onClick={() => setLabel()} style ={{color:'#070707', background: "#3333ff00",  border:"1px solid #808080", width: "50px", marginRight:"20px"}}/>
                </div>
                <div className="dialog-row end">
                    <Button type="button" label='export CSV' icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" style ={{color:'#070707', background: "#3333ff00",  border:"1px solid #808080", width: "150px", marginRight:"20px"}}/>
                </div>
            </div>
            
            <div>
                <div className="card">
                    <DataTable ref={dt} value={orders} paginator className="p-datatable-customers" header={header} rows={10}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
                        filters={filters} filterDisplay="menu" loading={loading} responsiveLayout="scroll"
                        globalFilterFields={['name', 'country', 'status', 'wallet']} emptyMessage="No orders found."
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        contextMenuSelection={selectedOrder}
                        onContextMenuSelectionChange={e => setSelectedOrder(e.value)}
                        onContextMenu={e => cm.current.show(e.originalEvent)}>
                        
                        <Column field="wallet" header="Wallet" sortable style={{ minWidth: '230px' }}></Column>
                        <Column field="name" header="Name" sortable style={{ maxWidth: '200px' }}></Column>
                        <Column field="country" header="Country" sortable  style={{ minWidth: '100px' }} ></Column>
                        <Column field="city" header="City"></Column>
                        <Column field="address1" header="Street address 1" style={{ minWidth: '200px' }}></Column>
                        <Column field="address2" header="Street address 2" style={{ minWidth: '200px' }}></Column>
                        <Column field="state" header="State"></Column>
                        <Column field="zip" header="Zip code / Postal"></Column>
                        <Column field="items" header="Items"></Column>
                        <Column field="phone" header="Phone"></Column>
                        <Column field="email" header="e-mail"></Column>
                        <Column field="twitter" header="Twitter"></Column>
                        <Column field="discord" header="Discord"></Column>
                        <Column field="date" header="Date" sortable  style={{ minWidth: '100px' }} body={dateBodyTemplate}/>
                        <Column field="status" header="Status" sortable style={{ minWidth: '5rem' }} body={statusBodyTemplate} ></Column>
                    </DataTable>
                </div>
            </div>
                
            </Dialog>
        <div className='admin-btn'>
            <Button label={"ADM"} style ={{color:'#070707', background: "#3333ff00",  border:"1px solid #808080", width: "157px"}} className=" p-button-info p-button-text" onClick={() => setDisplayAdmin(true)}  />
        </div>
        </>
        

    )
}

export default Admin