import {useState, useEffect, useContext} from 'react'
import FAQ from './FAQ'

import './Info.css'
import Section from './Section'
import DappContext from "../../context";

const Info = () => {
    const {isAuthenticated, cs} = useContext(DappContext);
    const [phase, setphase] = useState(0)
    useEffect(() => {

        isAuthenticated && setphase(cs.phase)
    }, [isAuthenticated])

    const gm = {
        1:"This is Siblings first NFT Physical Merch drop where NFTs can be remdeemed for physical merch. The physical merch will be exclusive, limited edition 1 year anniversary hoodies representing the year we have grown together!"
    }
    const phase1 = {
        1: "Begins Monday, May 23th, at 10am EST",          
        2: "This phase will include the 48-hour sale, a week-long redemption period starting from the time of mint, and free trade of unclaimed NFTs.",
        3: "During this first week, Siblings can buy and sell their NFTs on the secondary market, before they are redeemed for a hoodie.",
        4: "Hoodies can only be obtained through redeeming your NFT!",
        5: "Maybe you missed the mint. Maybe you want to get another hoodie, for your grandma. Maybe you’re hoping to profit from a quick flip. Maybe you want to have multiple unclaimed NFTs, one to redeem and one to keep, to complete the collection. THE CHOICE IS YOURS!"
    }
    const phase2 = {
        1: "Begins Monday, May 30th, at 10am EST",          
        2: "All unredeemed NFTs will be locked to the holder’s wallet.",
        3: "You won't be able to sell or transfer the NFT - but you can still redeem your NFT for a hoodie.",
        4: "This will be your last chance to get an exclusive NFT Siblings Anniversary Hoodie!"
    }
    const phase3 = {
        1: "Begins Monday, June 6th, at 10am EST",          
        2: "The redemption period will officially end and you will NOT be able to redeem your NFT for hoodie any longer.",
        3: "All NFTs will continue to be locked to the holder's wallet.",
        4: "All NFTs that were not redeemed for a hoodie will be transformed into a new NFT!",
        5: "The transformation will symbolize the holder’s choice to keep the NFT instead of choosing the physical hoodie - immortalized on the blockchain forever!",
        6: "With this phase we conclude the project."
    }
    const phase4 = {
        1: "Until when one day many many years later, this may become a highly sought after OG collectible item…"
    }
    

    return (
        <>
            <Section 
                title="GM Siblings!"
                text={gm}
                side={""}
                clas={"gm"}
                phase= {false}
                active={false}
            />
            <Section 
                title="Phase 1"
                text={phase1}
                side={"lleft"}
                clas={""}
                phase= {true}
                active={phase === 1 ? true: false}
            />
            <Section 
                title="Phase 2"
                text={phase2}
                side={"rright"}
                clas={""}
                phase= {true}
                active={phase === 2 ? true: false}
            />
            <Section 
                title="Phase 3"
                text={phase3}
                side={"lleft"}
                clas={""}
                phase= {true}
                active={phase === 3 ? true: false}
            />
            <Section 
                title="Phase 4"
                text={phase4}
                side={"rright"}
                clas={""}
                phase= {true}
                active={phase === 4 ? true: false}
            />
            <FAQ />
           
        </>
    )
}

export default Info