
import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Section = ({title, text, side, clas, phase, active}) => {
    const controls = useAnimation();
    const { ref, inView } = useInView();
    

    useEffect(() => {
        if (inView) {
          controls.start('visible');
        }
        if (!inView) {
          controls.start('hidden');
        }
    }, [controls, inView]);

    const sectionVariant = {
        hidden: { opacity:0, scale:1,y:-200},
        visible: {
          opacity:1,
          scale:1,
          y:0,
          transition: {
            duration: 1.5,
            delay:0.1
          }
        }
      }
    return (
        <motion.div ref={ref} className={`info-container ${clas}`} initial="hidden" animate={controls} variants={sectionVariant}>
            <div className={`section ${side}`}>
                {phase
                ?<div className='title sm'>
                    {title}
                    <span aria-hidden="true">{title}</span>
                    <span aria-hidden="true">{title}</span>
                    <span aria-hidden="true">{title}</span>
                    <span aria-hidden="true">{title}</span>
                </div>
                :<div className='titlex sm'>
                    {title}
                    
                </div>
                }
                
                
                {active && <div className='linex'></div>}
                {/* {phase && <div className='line'></div>} */}
                
                <div className='text'>{
                    Object.entries(text).map(([key,value])=>{
                        return(<div className='paragraph' key={key}>{value}</div>)
                    })
                }
                </div>
            </div>
        </motion.div>
    )
}

export default Section