import {useState, useEffect, useRef}from 'react'
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';

import "./Items.css"
import Form from './Form';
import TxHandler from './TxHandler';

const Items = ({amount, isMerch, close, showToast, makeTx}) => {
    const toast = useRef()
    const [items, setitems] = useState([])
    const [isForm, setisForm] = useState(false)
    const [isTx, setisTx] = useState(false)

    const [maxRedeem, setMaxRedeem] = useState(0)
    const [redeemAmounts, setredeemAmounts] = useState([])
    const [redeem_amount, setRedeemAmount] = useState({'name': 1})

    const [quantity, setquantity] = useState(0)
    
    const sizes = [{"name":"XS"},
        {"name":"S"},
        {"name":"M"},
        {"name":"L"},
        {"name":"XL"},
        {"name":"XXL"},]

    const onSizeChange = (index, value) => {
        var newItems = [...items];
        newItems[index].size = value;
        setitems(newItems);
    }
    const onQuantityChange = (index, value) => {
        var newItems =[...items];
        newItems[index].quantity = value;
        setitems(newItems);
    }

    const add =() =>{   
        if(items.length < amount){
            const newItem =  {'id': items.length, 'size': '', 'quantity': 1}
            setitems(items => [...items, newItem])
        }
    }

    const remove =(index) =>{
        var it = items;
        it.splice(index, 1);
        setitems(it)
    }
    const calcTotalQuantity =() =>{
        var q = 0;
        for(var x=0;x<items.length; x++){
            q+=items[x].quantity
        }
        return q;
    }
    const checkItemsSizes=()=>{
        var q = true;
        for(var x=0;x<items.length; x++){
            if(items[x].size === ''){
                q = false
            }
        }
        return q;
    }
    const proceed = () =>{
        if(isMerch){
            if(items.length >0 ){
                var totalQuantity = calcTotalQuantity();
                var sizeValidated = checkItemsSizes()
                if(!sizeValidated){
                    toast.current.show({severity: 'warn', summary: "", detail: "Please select size of the item that you want to redeem.", life: 10000});

                }else{
                    if(totalQuantity<=amount){
                        setquantity(totalQuantity)
                        setisForm(true)
                    }else{
                        toast.current.show({severity: 'warn', summary: "", detail: "Quantity of selected items surpass amount of tokens to redeem", life: 10000});
                    }
                }
                
            }else{
                toast.current.show({severity: 'warn', summary: "", detail: "You need to add at least one item.", life: 10000});
            }
        }else{
            setisTx(true)
        }
       
        
    }
    const calculateMax = () =>{
        var max = 1;
        if(items.length === 1){
            max = amount;
        }else{
            max = Math.round(items.length/amount)
        }
        return max
    }

    useEffect(() => {
        redeemAmountList();
    }, [])
    
    const redeemAmountList = async() =>{
        const list = []
        for(let x =1; x<=amount; x++){
            list.push({name:x})
        }
        setredeemAmounts(list)
    }

    return (
      <>
        <Toast ref={toast} position="top-center" />
        {isMerch 
            ?isForm
                ?<Form items={items} amount={quantity} close={() =>close()} showToast={(data)=>showToast(data)} makeTx={makeTx}/>
                :<div className='items-container'>
                <div className="item-top">
                    <p className='redeem-list-msg'> You are able to redeem {amount} SIB-versary Hoodie(s)! <br/>You will receive a Physical Hoodie and your current NFT will be transformed into a Virtual Hoodie. Add items using the + button and then select the size and quantity. You will then be lead to fill out your shipping details.</p>   
                    <Button icon="pi pi-plus" style ={{color:'#070707', background: "#3333ff00", border:"1px solid #808080", width: "40px", marginLeft:"10px"}} className="p-button-info p-button-text" onClick={()=> add()} />
                </div>
                <div className='items-list'>
                    {items.map((item, index)=>{
                        return(
                            <div key={index} className="item">
                                <span className="p-float-label">
                                    <Dropdown style={{minWidth:"120px"}} id="size" name="size" value={item.size} onChange={(e) => onSizeChange(index, e.value)} options={sizes} optionLabel="name" />
                                    <label htmlFor="size">Size</label>
                                </span>
                                <span className="p-float-label">
                                    <InputNumber size={1} style={{minWidth:"120px"}} inputId="quantity" value={item.quantity} onValueChange={(e) => onQuantityChange(index, e.value)} min={1} max={amount}/>
                                    <label  htmlFor="quantity">Quantity</label>
                                </span>
                                <Button icon="pi pi-trash" style ={{color:'#070707', background: "#3333ff00", width: "40px", marginLeft:"0px"}} className="p-button-info p-button-text" onClick={()=> remove(index)} />
                            </div>
                        )
                    })}
                </div>
                <div className="continue-button">
                    <Button label="Continue" style ={{color:'#070707', background: "#3333ff00", border:"1px solid #808080", width: "140px", marginLeft:"10px"}} className="p-button-info p-button-text" onClick={()=> proceed()} />
                </div>
                </div>
            :!isTx
                ?<><div className='items-container nft'>
                    <div className="item2">
                        <p className='redeem-list-msg'> You are able to redeem {amount} SIB-versary Hoodie(s) NFTs!<br/> Your current NFT will ONLY be transformed into a Virtual Hoodie. You WON'T be receiving a physical hoodie.<br/>Select the amount of NFTs you'd like to transform.</p>
                        <Dropdown value={redeem_amount} options={redeemAmounts} onChange={(e) => {setRedeemAmount(e.value)}} optionLabel="name" placeholder="1" />
   
                    </div>
                    <div className="continue-button">
                        <Button label="Continue" style ={{color:'#070707', background: "#3333ff00", border:"1px solid #808080", width: "140px", marginLeft:"10px"}} className="p-button-info p-button-text" onClick={()=> proceed()} />
                    </div>
                </div>
               
                </>
                :<TxHandler merch={false} amount={redeem_amount.name} close={() =>close()} showToast={(data)=>showToast(data)}/>

      }
      
        </>
    )
}

export default Items