import {useState, useContext, useEffect, useRef} from 'react'
import {db} from "../../utils/firebase";
import { doc, deleteDoc,collection, setDoc, getDocs, updateDoc} from "firebase/firestore";

import DappContext from '../../context';


import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
 
import { Toast } from 'primereact/toast';

import Form from './Form';

import './Redeem.css' 
import Items from './Items';


const Redeem = ({ checkBalance, balance}) => {
    const toast = useRef();

    const {account} = useContext(DappContext);

    const [displayBasic, setDisplayBasic] = useState(false);
    const [isMerch, setisMerch] = useState(false)

    const showPopup = (merch) =>{
        setDisplayBasic(true);
        setisMerch(merch)
    }
    const close = () =>{
        checkBalance()
        setDisplayBasic(false)
    } 

    const showToast = async(data) =>{
        toast.current.show(data[0]);
        const idx  = await getIds()
        if(data[1]){
            const idx  = await getIds(data[1])
            await deleteDoc(doc(db, "merch_orders", idx));
        }else{
        } 
    }

    const getIds = async() =>{
        const querySnapshot = await getDocs(collection(db, "merch_orders"))
        var id = 1;
        querySnapshot.forEach((doc) => {
            id++;
        });
        var idd = id -1
        return idd.toString();
    }


    return (
        <>  
            <Toast ref={toast} position="top-center" />
            <Dialog visible={displayBasic} style={{ width: '50vw', color:"#000", minHeight: "500px" }}  onHide={() => close()} breakpoints={{'960px': '75vw', '640px': '100vw'}}>
                {   
                    <Items amount={balance} isMerch={isMerch} close={() => close()} showToast={(data)=>showToast(data)} makeTx={true}/>
                }   
            </Dialog>
            <div className='redeem-button'>
                <Button label="Redeem physical + virtual" style ={{color:'#070707', background: "#3333ff00", border:"1px solid #808080", width: "177px"}} className="p-button-info p-button-text" onClick={() => showPopup(true)} />
                <Button label="Redeem virtual" style ={{color:'#070707', background: "#3333ff00", border:"1px solid #808080", width: "177px"}} className="p-button-info p-button-text" onClick={() => showPopup(false)} />
            </div>
        </>
    )
}

export default Redeem