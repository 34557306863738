import {useState} from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';

import "./FAQ.css"
const FAQ = () => {

    return (
        <div className="faq">
            <Accordion className="accordion">
                <AccordionTab header="Wen Mint?">
                    <p>There will be a max mint amount of 1 NFT per wallet. Although, you may buy as many as you'd like on secondary.</p>
                </AccordionTab>
                <AccordionTab header="How much will mint be?">
                    <p>The public sale price will be 0.03ETH or 15 ASH. Those on the allow list will be given a family discount if minted before the public sale begins and can purchase their NFT for 0.01ETH or 5 ASH.</p>
                </AccordionTab>
                <AccordionTab header="What is the max mints per wallet?">
                    <p>The public sale price will be 0.03ETH or 15 ASH. Those on the allow list will be given a family discount if minted before the public sale begins and can purchase their NFT for 0.01ETH or 5 ASH.</p>
                </AccordionTab>
                <AccordionTab header="What is the max supply?">
                    <p>There will be a max supply of 100 NFTs.</p>
                </AccordionTab>
                <AccordionTab header="Wen can I redeem my NFT for a physical Hoodie?">
                    <p>You will be able to redeem your NFTs minted during the sale or bought on secondary on Monday, May 30th, at 10am EST</p>
                </AccordionTab>
                <AccordionTab header="How long do I have to redeem my NFT for a physical Hoodie?">
                    <p>You will be able to redeem your NFTs until Monday, June 6th, at 10am EST. After that date you will no longer be able to redeem but will still have an awesome NFT!</p>
                </AccordionTab>
                <AccordionTab header="How do I tell the difference between unredeemed and redeemed NFTs?">
                    <p>The unredeemed NFTs will have blue writing on the image of the hoodies. The redeemed NFTs will have a gliched design on the hoodies.</p>
                </AccordionTab>
                <AccordionTab header="Can I redeem the NFT for a hoodie if I bought it on the secondary market?">
                    <p>Yes! As long as you redeem the hoodie before the redemption period ends, you can do so from any wallet that contains the NFT.</p>
                </AccordionTab>
                <AccordionTab header="What happens if I have multiple unredeemed NFTs, can I redeem more than one?">
                    <p>Yes, you can redeem as many as you’d like. Feel free to get multiple and have the whole family swagged out!</p>
                </AccordionTab>
                <AccordionTab header="Do I have to give my real name and physical address?">
                    <p>Yes, you will need to provide a real name and a physical address that we can send the merch to. </p>
                </AccordionTab>
                <AccordionTab header="Can I redeem my NFT and not give my personal information?">
                    <p>Yes, you are free to redeem your NFT and not claim the physical merch. You can also send it to another Sibling and keep the NFT. The choice is yours.</p>
                </AccordionTab>
                <AccordionTab header="Will my personal information be safe?">
                    <p>Yes, only trusted Siblings staff will ever see your personal information and we will not be publicly posting it anywhere.</p>
                </AccordionTab>
                <AccordionTab header="How long will shipping take?">
                    <p>Expect 8-12 weeks for your Anniversary Hoodies to be delivered after the end of the redemption period beginning Phase 3; considering we cannot begin production until we have all the sizes in. The hoodies will need to go through a few manufacturing sites before being shipped to you.</p>
                </AccordionTab>
                <AccordionTab header="Why is metamask showing me an error when I'm trying to buy the NFT off secondary?">
                    <p>All redeemed NFTs will forever be locked and unable to be transfered or sold. If this is also happening to unredeemed NFTs then that means we are no either in phase 2 or 3 of the project and these NFTs can only be redeemed by the wallets currently holding them.</p>
                </AccordionTab>
            </Accordion>
        </div>
    )
}

export default FAQ