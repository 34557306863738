import { useEffect, useState }from 'react'

import './Loader.css'
import Mint from "../Mint/Mint";
import Info from '../Info/Info';


export const Loader = () => {
    const [show, setShow] = useState(false)
    useEffect(() => {
      setTimeout(()=>{
        setShow(true)
      }, 3000)
    
      
    }, [])
    

    return (
        <div className='main-container'>
            {!show 
            ?<>
                <div className='light cntr'></div>
                <div className='light left'></div>
                <div className='light right'></div>
                <div className='M'>
                    <span aria-hidden="true">M</span>
                    <span aria-hidden="true">M</span>
                    <span aria-hidden="true">M</span>
                    M
                    <span aria-hidden="true">M</span>
                </div>
            
                <div className='circle'></div>
                <div className='circle2'></div>
                <div className='circle2 circle3'></div>
                <div className='circle2 circle4'></div>
                
            </>
            : 
            <>
                <Mint /> 
                <Info />
                <div className="footnote">
                    <div className='foot-text'>©️All rights reserved to Siblings Co.</div>
                </div>
            </>
            }
            {/*  */}
        </div>
    )
}
