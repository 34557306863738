import {useState, useRef, useEffect} from 'react'

import './Popup.css'

import {mintToken, approve, getPrice} from "../../utils/interactions"

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';    
import { Toast } from 'primereact/toast';

const Popup = ({setMinted, isAuthenticated, isApproved}) => {

    const toast = useRef();

    const [displayMint, setDisplayMint] = useState(false)

    const [txHash, setTxHash] = useState("")
    const [processing, setProcessing] = useState(false)
    const [successfullyMinted, setSuccessfullyMinted] = useState(false)

    const [ethPrice, setEthPrice] = useState("")
    const [ashPrice, setAshPrice] = useState("")

    useEffect(() => {
        if(txHash !== ""){
            setProcessing(false)
        }
        
    }, [txHash])

    const showPopup = (ash) =>{
        setDisplayMint(true);
        mint(ash);
    }

    const mint = async(ash) =>{
        setProcessing(true);
        mintToken(ash).then(async (tx)=>{
            setTxHash(tx.hash);
            const receipt = await tx.wait();
            
            setProcessing(false)
            setSuccessfullyMinted(true);
            setMinted(true)
        }).catch((e)=>{
            setProcessing(false)
            setDisplayMint(false)
            if(e.error){
                toast.current.show({severity: 'warn', summary: "Error", detail: e.error.message, life: 300000});
            }else{
                toast.current.show({severity: 'warn', summary: "Error", detail: e.message, life: 300000});
            }
            setSuccessfullyMinted(false);
            
        })
    }
    useEffect(() => {
        setSuccessfullyApproved(isApproved);
        async function price(){
            const {eth_price, ash_price} = await getPrice();
            console.log(eth_price, ash_price)
            setEthPrice(eth_price)
            setAshPrice(ash_price)
        }
        price();
    }, [isAuthenticated])

    const [processingApprove, setProcessingApprove] = useState(false)
    const [successfullyApproved, setSuccessfullyApproved] = useState(false)
    const approveErc20 = async() =>{
        setProcessingApprove(true);
        approve().then(async (tx)=>{
            const receipt = await tx.wait();
            setSuccessfullyApproved(true);
            setProcessingApprove(false)
        }).catch((e)=>{
            setProcessingApprove(false)
            if(e.message){
                toast.current.show({severity: 'warn', summary: "Error", detail: e.message, life: 300000});
            }else{
                toast.current.show({severity: 'warn', summary: "Error", detail: e.error.message, life: 300000});
            }
            setSuccessfullyApproved(false);
            
        })
    }
    return (
        <>
        <Toast ref={toast} position="top-center" />
        <Dialog header="" visible={displayMint} style={{ width: '30vw', height:"430px", backgroundColor: "#F6F6F6"}}  onHide={() => setDisplayMint(false)} breakpoints={{'960px': '75vw', '640px': '100vw'}}>
            {processing
                ?<div className='load'>
                    <br/>
                    <ProgressSpinner />
                    <br/>
                    <br/>
                    <div className='message'>Confirm transaction in your wallet...</div>
                </div>
                :txHash !== "" 
                ? !successfullyMinted 
                    ?<div className='message'>
                        Your transaction has been submitted and is awaiting confirmation. You can view your transaction <a href={"https://etherscan.io/tx/" + txHash} target="_blank" rel="noopener noreferrer">here</a>
                        <br/>
                        <br/>
                        <ProgressSpinner />
                        <br/>
                    </div> 
                    : <div className='message'>
                        <div className='success'>SUCCESS</div>
                        <br/>
                        
                    </div> 
                : <div className='message'></div>
                
            }
            
        </Dialog>
        <div className="price">
            <div className="value">ASH price: {ashPrice}</div>
            <div className="value">ETH price: {ethPrice}</div>
        </div>
        {!successfullyApproved
        ?<div className='buttons'>

            <Button label={processingApprove? "Waiting" :"Approve ASH"} style ={{color:'#070707', background: "#3333ff00",  border:"1px solid #808080", width: "157px"}} className=" p-button-info p-button-text" onClick={() =>{approveErc20()}} iconPos="right" loading={processingApprove}/>
            <Button label={`Mint with ETH`} style ={{color:'#070707', background: "#3333ff00",  border:"1px solid #808080", width: "157px"}} className=" p-button-info p-button-text" onClick={() =>{showPopup(false)}}/>
        </div>
        :<div className='buttons'>
            <Button label={`Mint with ASH`} style ={{color:'#070707', background: "#3333ff00",  border:"1px solid #808080", width: "157px"}} className=" p-button-info p-button-text" onClick={() =>{showPopup(true)}}/>
            <Button label={`Mint with ETH`} style ={{color:'#070707', background: "#3333ff00",  border:"1px solid #808080", width: "157px"}} className=" p-button-info p-button-text" onClick={() =>{showPopup(false)}}/>
        </div>
        }
        </>
    )
}

export default Popup