import React, { useEffect, useState, useContext, useRef } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { data } from '../../utils/countries';



import './Form.css';
import DappContext from '../../context';

import {db} from "../../utils/firebase";
import { collection, setDoc, getDocs, doc} from "firebase/firestore";
import Items from './Items';
import TxHandler from './TxHandler';

const Form = ({ items, amount, close, showToast, makeTx}) => {

    const {account} = useContext(DappContext);

    const [countries, setCountries] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});

    const [currentId, setcurrentId] = useState(null)
    const [successMessage, setSuccessMessage] = useState(false)

    const sizes = [{"name":"XS"},
                    {"name":"S"},
                    {"name":"M"},
                    {"name":"L"},
                    {"name":"XL"},
                    {"name":"XXL"},]

    useEffect(() => {
        setCountries(data);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            streetAddress: '',
            streetAddress2: '',
            city: '',
            state: '',
            zip: '',
            country: null,
            accept: false, 
            size: null,
        },
        validate: (data) => {
            let errors = {};

            if (!data.firstName) {
                errors.firstName = 'First name is required.';
            }
            if (!data.lastName) {
                errors.lastName = 'Last name is required.';
            }
            if (!data.streetAddress) {
                errors.streetAddress = 'Street address is required.';
            }
            if (!data.city) {
                errors.city = 'City is required.';
            }
            if (!data.state) {
                errors.state = 'State/Province code is required.';
            }
            if (!data.zip) {
                errors.zip = 'Zip code is required.';
            }
            if (!data.email) {
                errors.email = 'Email is required.';
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Invalid email address. E.g. example@email.com';
            }


            // if (!data.accept) {
            //     errors.accept = 'You need to agree to the terms and conditions.';
            // }

            return errors;
        },
        onSubmit: (data) => {
            console.log(items)
            setFormData(data);
            const parsedItems = parseItems();
            addOrder(data, parsedItems);
            console.log("current ID from FORM: ", currentId)
            
            makeTx ? setShowMessage(true) : setSuccessMessage(true)
            // console.log(data)
            formik.resetForm();
           
           
        }
    });
    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getIds = async() =>{
        const querySnapshot = await getDocs(collection(db, "merch_orders"))
        var id = 1;
        querySnapshot.forEach((doc) => {
            id++;
        });
        setcurrentId(id.toString())
        return id.toString();
    }

    const addOrder = async(data, parsedItems) => {
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        const idx = await getIds();
        
        await setDoc(doc(db, "merch_orders", idx), {
            id: idx,
            name: `${data.firstName}  ${data.lastName}`,
            state: data.state,
            country: data.country.name,
            city: data.city,
            address1: data.streetAddress,
            address2: data.streetAddress2,
            zip: data.zip, 
            date: date,
            status: "waiting", 
            wallet: account, 
            email: data.email,
            phone: data.phone,
            discord: data.discord !== undefined ? data.discord : "N/A",
            twitter: data.twitter !== undefined ? data.twitter : "N/A", 
            items: parsedItems, 
            quantity: amount
          });
       
    }
    const parseItems = () => {
        var parsed = ""
        items.map((item)=>{
            return(
                parsed += `${item.size.name}-${item.quantity}; `
            )
        })
        return parsed;
    }
    
    return (
        <>
        <div className="form-demo">
            {!showMessage 
                ? !successMessage
                    ?
                    <div className="form-container">
                        
                        <div className='form-description'>Please provide your shipping details for delivery.</div>
                        <div className="card">
                            {/* <h5 className="text-center">Register</h5> */}
                            
                            <form onSubmit={formik.handleSubmit} className="p-fluid">
                                
                                <div className="field">
                                    <span className="p-float-label">
                                        <InputText id="firstName" name="firstName" value={formik.values.firstName} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('firstName') })} />
                                        <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') })}>First name</label>
                                    </span>
                                    {getFormErrorMessage('firstName')}
                                </div>
                                <div className="field">
                                    <span className="p-float-label">
                                        <InputText id="lastName" name="lastName" value={formik.values.lastName} onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('lastName') })} />
                                        <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') })}>Last name</label>
                                    </span>
                                    {getFormErrorMessage('lastName')}
                                </div>
                                
                                
                                <div className="field">
                                    <span className="p-float-label">
                                        <InputText id="streetAddress" name="streetAddress" value={formik.values.streetAddress} onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('streetAddress') })} />
                                        <label htmlFor="streetAddress" className={classNames({ 'p-error': isFormFieldValid('streetAddress') })}>Street address</label>
                                    </span>
                                    {getFormErrorMessage('streetAddress')}
                                </div>
                                
                                <div className="field">
                                    <span className="p-float-label">
                                        <InputText id="streetAddress2" name="streetAddress2" value={formik.values.streetAddress2} onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('streetAddress2') })} />
                                        <label htmlFor="streetAddress2" className={classNames({ 'p-error': isFormFieldValid('streetAddress2') })}>Street address line 2</label>
                                    </span>
                                    {getFormErrorMessage('streetAddress2')}
                                </div>
                                <div className="field">
                                    <span className="p-float-label">
                                        <InputText id="city" name="city" value={formik.values.city} onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('city') })} />
                                        <label htmlFor="city" className={classNames({ 'p-error': isFormFieldValid('city') })}>City</label>
                                    </span>
                                    {getFormErrorMessage('city')}
                                </div>
                                
                                    <div className="field">
                                        <span className="p-float-label">
                                            <InputText id="state" name="state" value={formik.values.state} onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('state') })} />
                                            <label htmlFor="state" className={classNames({ 'p-error': isFormFieldValid('state') })}>State / Province</label>
                                        </span>
                                        {getFormErrorMessage('state')}
                                    </div>
                                    <div className="field">
                                        <span className="p-float-label">
                                            <InputText id="zip" name="zip" value={formik.values.zip} onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('zip') })} />
                                            <label htmlFor="zip" className={classNames({ 'p-error': isFormFieldValid('zip') })}>Zip code</label>
                                        </span>
                                        {getFormErrorMessage('zip')}
                                    </div>
                                
                                <div className="field">
                                    <span className="p-float-label">
                                        <Dropdown id="country" name="country" value={formik.values.country} onChange={formik.handleChange} options={countries} optionLabel="name" />
                                        <label htmlFor="country">Country</label>
                                    </span>
                                    {getFormErrorMessage('country')}
                                </div>
                                {/* <div className="field size">
                                    <span className="p-float-label">
                                        <Dropdown id="size" name="size" value={formik.values.size} onChange={formik.handleChange} options={sizes} optionLabel="name" />
                                        <label htmlFor="size">Size</label>
                                    </span>
                                    {getFormErrorMessage('size')}
                                </div> */}
                                <div>Please provide your preferred contact method(s) in case we need to contact you</div>
                                <div className="field">
                                    <span className="p-float-label p-input-icon-right">
                                        <i className="pi pi-phone" />
                                        <InputText id="phone" name="phone" value={formik.values.phone} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('phone') })} />
                                        <label htmlFor="phone" className={classNames({ 'p-error': isFormFieldValid('phone') })}>Phone number</label>
                                    </span>
                                    {getFormErrorMessage('phone')}
                                </div>
                                <div className="field">
                                    <span className="p-float-label p-input-icon-right">
                                        <i className="pi pi-envelope" />
                                        <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                                        <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email</label>
                                    </span>
                                    {getFormErrorMessage('email')}
                                </div>
                                <div className="field">
                                    <span className="p-float-label p-input-icon-right">
                                        <i className="pi pi-discord" />
                                        <InputText id="discord" name="discord" value={formik.values.discord} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('discord') })} />
                                        <label htmlFor="discord" className={classNames({ 'p-error': isFormFieldValid('discord') })}>Discord ID</label>
                                    </span>
                                    
                                </div>
                                <div className="field">
                                    <span className="p-float-label p-input-icon-right">
                                        <i className="pi pi-twitter" />
                                        <InputText id="twitter" name="twitter" value={formik.values.twitter} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('twitter') })} />
                                        <label htmlFor="twitter" className={classNames({ 'p-error': isFormFieldValid('twitter') })}>Twitter handle</label>
                                    </span>
                                    
                                </div>
                                
                                
                                {/* <div className="field-checkbox">
                                    <Checkbox inputId="accept" name="accept" checked={formik.values.accept} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('accept') })} />
                                    <label htmlFor="accept" className={classNames({ 'p-error': isFormFieldValid('accept') })}> I agree to the terms and conditions*</label>
                                </div> */}

                                <Button type="submit" label="Submit" className="mt-2" />
                            </form>
                        </div>
                    </div>
                    : <div className="successMessage">Congrats! You have successfully redeemed your physical merch.</div>
                :<TxHandler merch={true} amount={amount} close={() =>close()} showToast={(data)=>showToast(data)} idx={currentId}/>
            }
        </div>
        </> 
    )
}

export default Form