import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { doc, setDoc, getDoc } from "firebase/firestore"; 


const firebaseConfig = {
  apiKey: "AIzaSyBEvvg8xONjPWG2tGTVPExmxbsLcRXxLuM",
  authDomain: "merch-database-b9d6a.firebaseapp.com",
  projectId: "merch-database-b9d6a",
  storageBucket: "merch-database-b9d6a.appspot.com",
  messagingSenderId: "532979307347",
  appId: "1:532979307347:web:47321309419df36576c7a6"
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth

const db = firebaseApp.firestore();
const auth = firebase.auth();

const addDocument = async (col, docName, data) => {
  await setDoc(doc(db, col, docName), data);
}

const getDocument = async (col, docName) => {
  const docRef = doc(db, col, docName);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return false;
  }
}


export { auth, db, addDocument, getDocument};