import {useRef, useEffect, useState} from "react";



import './App.css';
import "primereact/resources/themes/mdc-dark-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";     

import { Toast } from 'primereact/toast';


import DappContext from "./context";
import Account from "./utils/account" 
import {currentState} from './utils/interactions';

import { Loader } from "./components/Loader/Loader";

import {auth} from "./utils/firebase";



function App() {
  const toast = useRef();
  const {state, authenticate, logout} = Account(toast);
  const {account, tokens, isLoading, isAuthenticated, isError, approved, onList, minted, isAdmin, cs} = state;
  // const [cs, setcs] = useState()

  useEffect(() => {
    signIn();

  }, [])
  const signIn = async () => {
    auth.signInAnonymously()
    .then(() => {
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.error(errorCode, errorMessage)
    });
  }

  if(isError){
    toast.current.show({severity: 'warn', summary: 'Please switch to mainnet ethereum network and try again.', detail: ''});
  }
  // useEffect(() => {
  //   async function getcs(){
  //      const csx = await currentState();
  //      console.log("current state X: ", csx);
  //      setcs(csx);
  //      console.log("current state: ", cs);
  //   }
  //   console.log("init")
  //   getcs();
    
  // }, [])
  
  return (
    <>
    <Toast ref={toast}></Toast>
      <DappContext.Provider  value={{account, 
                                      isLoading, 
                                      isAuthenticated, 
                                      authenticate, 
                                      logout, 
                                      approved, 
                                      onList, 
                                      minted,
                                      cs, 
                                      isAdmin
                                      }}>
      <div className="App">
        
        <Loader />
        
        {/* <Mint /> */}
      </div>
    </DappContext.Provider>
    </>
  );
}

export default App;
