import {useEffect, useReducer} from 'react';
import { ethers } from "ethers";

import { isApproved } from './interactions';

import {isOnList, hasMinted, currentState} from './interactions';
import {db} from "./firebase";
import { collection, getDocs} from "firebase/firestore";

function loginReducer(state, action) {
    switch (action.type) {
      case 'login': {
        return {
          ...state,
          account: action.account,
          isAdmin: action.isAdmin,
          approved: action.approved,
          onList: action.onList,
          minted: action.minted,
          cs: action.cstate,
          tokens: '',
          isLoading: false,
          isAuthenticated: true,
          isError: false,

        };
      }
      case 'logout': {
        return {
          ...state,
          account: '',
          isAdmin: false,
          tokens: '',
          isAuthenticated: false,
          onList: false,
          minted: false,
          cs: "",
          isError: false,
          approved: false
        };
      }
      case 'reset': {
        return action.payload;
      }
      case 'error': {
        return {
          ...state,
          isError: action.isError,
          
        };
      }
      case 'loading': {
        return {
          ...state,
          isLoading: true,
          
        };
      }
      default: {
        return state;
      }
    }
  }
  
const initialState = {
    account: '',
    isAdmin: false,
    isLoading: false,
    isAuthenticated: false,
    approved: false,
    onList: false,
    minted: false,
    cs: "",
    network: '', 
    isError: false,
    tokens: "",
}

export default function Account() {
  
    const [state, dispatch] = useReducer(loginReducer, initialState)

    useEffect(() => {
        if(window.ethereum) {
            window.ethereum.on('chainChanged', () => {
            logout();
            })
            window.ethereum.on('accountsChanged', () => {
            logout();
            })
        return()=>{
            window.ethereum.on('chainChanged', () => {})
            window.ethereum.on('accountsChanged', () => {})
        }
    }})
    
  
    const authenticate = async () => {
        dispatch({type: 'loading'});
        // console.log("authenticating");
        if(window.ethereum){
            const networkId = await window.ethereum.request({ method: 'eth_chainId' });
            dispatch({type: 'error', isError: false });

            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const accounts = await provider.send("eth_requestAccounts", []);
            const approved = await isApproved(accounts[0]);
            const onList = await isOnList(accounts[0]);
            var isOnAllowList = false;
            if(onList.toNumber() === 0){
              isOnAllowList = false;
            }else{
              isOnAllowList = true;
            }
            const cs = await currentState();
            const admin = await db_getAdmins(accounts[0]);
            const minted = await hasMinted(accounts[0]);

            dispatch({type: 'login', 
                      account: accounts[0],
                      isAdmin: admin,
                      approved: approved,
                      onList: isOnAllowList,
                      minted: minted, 
                      cstate: cs
                    });
        }
    }
    const logout = () => {
      dispatch({type: 'logout', });
    }
    const db_getAdmins = async (address) => {
      const querySnapshot = await getDocs(collection(db, "merch"))
      const items = {}
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        items[doc.id] =  doc.data()
      });
      var isAdmin = false;
      items.admins["list"].forEach((add)=>{
        if(add.toUpperCase() === address.toUpperCase()){
          isAdmin = true;
        }
         
    })

      return isAdmin
    }

  

    return {state, authenticate, logout};
}