
import { ethers } from "ethers"
import {merch_abi, erc20_abi} from './ABI' 
// import {merch_abi, erc20_abi} from './TABI' 

const merchAddress = "0xc118d78a29B37ab395Cdc54ec8B3D36D80604A86"
const erc20 = "0x64D91f12Ece7362F91A6f8E7940Cd55F05060b92"

//TEST
// const merchAddress ="0x4b346766fD7bcb410c5Fc53302Cd332238c4888B"
// const erc20 = "0xBEDAcEf5AfC744B7343fcFa619AaF81962Bf82F2"

const initContracts = () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const merchContract = new ethers.Contract(merchAddress, merch_abi, signer);
    const erc20Contract = new ethers.Contract(erc20, erc20_abi, signer);
    return {merchContract, erc20Contract}
}



const currentState = async() => {
    if(!window.ethereum){
        return false
    } 
    const {merchContract} = initContracts();
    
    const ph = await merchContract.phase();
    const phase = ph.toNumber();
    const active = await merchContract.saleActive();
    const allowList = await merchContract.alRequired();
    const totalSupply = await merchContract.MAX_SUPPLY();
    const totalMinted = await merchContract.totalMints()
    return {phase, active, allowList, totalSupply, totalMinted}
}

const checkState = async() =>{
    if(!window.ethereum){
        return false
    } 
    const {merchContract} = initContracts();
    const totalMinted = await merchContract.totalMints()
    return totalMinted.toNumber();
}
const supply = async() =>{
    if(!window.ethereum){
        return false
    } 
    const {merchContract} = initContracts();
    const supply = await merchContract.MAX_SUPPLY();
    return supply.toNumber()
}
const isOnList = async(address) =>  {
    if(!window.ethereum){
        return false
    } 
    const {merchContract} = initContracts();
    const isOnList = await merchContract.allowlist(address);
    return isOnList;
}



const hasMinted = async(address) =>  {
    if(!window.ethereum){
        return false
    } 
    const {merchContract} = initContracts();
    const mintClaimed = await merchContract.mintClaimed(address);
    let hasMinted = false;
    mintClaimed > 0 ? hasMinted = true : hasMinted = false
    return hasMinted;
}

const balance = async(address, tokenId) =>{
    if(!window.ethereum){
        return false
    } 
    const {merchContract} = initContracts();
    const balance = await merchContract.balanceOf(address,tokenId);
    return balance.toNumber();
}

const approve = async() =>{
    if(!window.ethereum){
        return false
    } 
    const {erc20Contract} = initContracts();
    const approve = await erc20Contract.approve(merchAddress, "15000000000000000000");
    return approve;
}

const isApproved = async(address) => {
    if(!window.ethereum){
        return false
    } 
    const {erc20Contract} = initContracts();
    const approved = await erc20Contract.allowance(address, merchAddress);
    let isApproved = false;
    approved > 1 ? isApproved  = true: isApproved =  false;
    return isApproved;
    
}

const isFreeMint = async() =>{
    if(!window.ethereum){
        return false
    } 
    const {merchContract} = initContracts();
    const ashPrice = await merchContract.ASH_PRICE_AL()
    const ethPrice = await merchContract.ETH_PRICE_AL()
    if(ashPrice.toNumber()!== 0 && ethPrice.toNumber() !==0){
        return true
    }else{
        return false
    }
}

const getPrice = async()=>{
    if(!window.ethereum){
        return false
    } 
    const {merchContract} = initContracts();
    const allowList = await merchContract.alRequired();
    var ashprice = 0;
    var ethprice = 0;
    if(allowList){
        ethprice = await merchContract.ETH_PRICE_AL() 
        ashprice = await merchContract.ASH_PRICE_AL() 
    }else{
        ethprice = await merchContract.ETH_PRICE() 
        ashprice = await merchContract.ASH_PRICE() 
    }
    var eth_price = ethers.utils.formatEther(ethprice.toString())
    var ash_price = ethers.utils.formatEther(ashprice.toString()).split(".")[0]

    return {eth_price, ash_price}

}
const mintToken = async (ash) =>{
    if(!window.ethereum){
        return false
    } 
    const {merchContract} = initContracts();
    const allowList = await merchContract.alRequired();
    var price = 0;
    if(allowList){
        if(!ash){
            price = await merchContract.ETH_PRICE_AL() 
        }
    }else{
        if(!ash){
            price = await merchContract.ETH_PRICE() 
        }
    }
    console.log(ash)
    console.log("price", price.toString())
    const gasLimit = await merchContract.estimateGas.mint(ash, {value: price.toString()});
    let gas = gasLimit.add(gasLimit.div(4))
    const tx = await merchContract.mint(ash, {value: price.toString(), gasLimit:gas.toNumber()});
    return tx;

}

const redeem = async(amount) =>{
    if(!window.ethereum){
        return false
    } 
    console.log("redeem2")
    const {merchContract} = initContracts();
    const tx = await merchContract.redeem(amount);
    return tx;
}

const activateSale = async (sale) =>{
    if(!window.ethereum){
        return false
    } 
    const {merchContract} = initContracts();
    const tx = await merchContract.setSaleActive(sale);
    return tx;
}


export {currentState, supply, hasMinted, isApproved, approve, mintToken, isOnList, balance, activateSale, isFreeMint, redeem, checkState, getPrice}
