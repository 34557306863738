import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from '@react-three/fiber'

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/hoodie.gltf");
  useFrame((state, delta) => (group.current.rotation.z += 0.01))
  return (
    <group ref={group} {...props}  dispose={null} scale={0.75} rotation={[Math.PI / 2, 0, 0]} >
      <mesh
        position={[0, 0, 0]}
        castShadow
        receiveShadow
        geometry={nodes.hoodie.geometry}
        material={materials["mat001"]}
      />
    </group>
  );
}

useGLTF.preload("/hoodie.gltf");